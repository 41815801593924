import React, { Component } from "react";
import CustomerService from "./CustomerService";
import { connect } from "react-redux";
import { Switch, Route, HashRouter } from "react-router-dom";
import Choose from "./Choose";
import ActiveEmployees from "./Warehouse/ActiveEmployees";
import RmaDashboard from "./Warehouse/Rma/RmaDashboard";
import Content from "./Content";
import WarehouseToppy from "./WarehouseToppy";
import WarehouseHeuts from "./WarehouseHeuts";
import WarehousePakket from "./WarehousePakket";
import WarehousePallet from "./WarehousePallet";
import WarehouseInbound from "./WarehouseInbound";

class SelectBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        show: false,
      });
    }
  }

  render() {
    return (
      <div>
        <HashRouter exact basename="/">
          <Switch>
            <Route exact path="/customer-service" component={CustomerService} />
            <Route exact path="/warehouse" component={WarehouseToppy} />
            <Route exact path="/warehouse/heuts" component={WarehouseHeuts} />
            <Route exact path="/warehouse/settings" component={ActiveEmployees}/>
            <Route exact path="/rma" component={RmaDashboard} />
            <Route exact path="/warehouse/pakket" component={WarehousePakket} />
            <Route exact path="/warehouse/pallet" component={WarehousePallet} />
            <Route exact path="/warehouse/inbound" component={WarehouseInbound} />
            <Route exact path="/content" component={Content} />
            <Route exact path="/">
              <Choose />
            </Route>
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = (state = {}) => {
  if (
    typeof state.data !== "undefined" &&
    typeof state.data["event-current-stats"] !== "undefined"
  ) {
    let result = {};

    state.data["event-current-stats"].forEach(function (current_stat_response) {
      result[current_stat_response.label] = current_stat_response.result;
    });

    return {
      data: result,
    };
  }

  return {
    data: null,
  };
};

export default connect(mapStateToProps)(SelectBoard);
