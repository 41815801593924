import React, {Component} from "react";
import SelectBoard from "./Components/SelectBoard";
import GoogleLogin from "react-google-login";
import publicIp from "public-ip";
import "./SCSS/App.scss";
import ProfilePopup from "./Components/common/Profile/ProfilePopup.js";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store";
import Moment from 'react-moment';
import setInitialCookie from './cookies';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: !!localStorage.getItem("user"),
            show: false,
            language: ""
        };

        publicIp.v4().then(ip => {
            if (ip === "81.173.59.30") {
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        displayName: "Content",
                        emails: [{value: "marketing@toppy.nl"}],
                        _json: {
                            picture: "favicon.ico"
                        }
                    })
                );
                this.setState({isAuthenticated: true});
                if (window.location.href === '/'
                    || window.location.href === ''
                    || window.location.hash === '#'
                    || window.location.hash === '#/') {
                    window.location.href = '/#/content'
                }
            }
            if (ip === "81.173.49.225") {
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        displayName: "Klantenservice",
                        emails: [{value: "klantenservice@toppy.nl"}],
                        _json: {
                            picture: "favicon.ico"
                        }
                    })
                );
                this.setState({isAuthenticated: true});
                if (window.location.href === '/'
                    || window.location.href === ''
                    || window.location.hash === '#'
                    || window.location.hash === '#/') {
                    window.location.href = '/#/customer-service'
                }
            }
            if (ip === "81.23.247.87" || ip === "81.173.59.29") {
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        displayName: "Magazijn",
                        emails: [{value: "logistiek@toppy.nl"}],
                        _json: {
                            picture: "favicon.ico"
                        }
                    })
                );
                this.setState({isAuthenticated: true});
                if (window.location.href === '/'
                    || window.location.href === ''
                    || window.location.hash === '#'
                    || window.location.hash === '#/') {
                    window.location.href = '/#/warehouse'
                }
            }
            if (ip === "46.144.162.26") {
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        displayName: "Heuts",
                        emails: [{value: "logistiek@heuts.nl"}],
                        _json: {
                            picture: "favicon.ico"
                        }
                    })
                );
                this.setState({isAuthenticated: true});
                if (window.location.href === '/'
                    || window.location.href === ''
                    || window.location.hash === '#'
                    || window.location.hash === '#/') {
                    window.location.href = '/#/warehouse/heuts'
                }
            }
        });
    }

    logout = () => {
        this.setState({isAuthenticated: false});
        localStorage.removeItem("user");
    };

    googleResponse = response => {
        const tokenBlob = new Blob(
            [JSON.stringify({access_token: response.accessToken}, null, 2)],
            {type: "application/json"}
        );
        const options = {
            method: "POST",
            body: tokenBlob,
            mode: "cors",
            cache: "default"
        };
        var backendUrl = window.location.protocol + '//socket.' + window.location.hostname;

        fetch(
            backendUrl + "/api/v1/auth/google",
            options
        ).then(r => {
            const token = r.headers.get("x-auth-token");
            r.json().then(user => {
                if (token) {
                    localStorage.setItem("user", JSON.stringify(user));
                    this.setState({isAuthenticated: true});
                }
            });
        });
    };

    onFailure = error => {
        console.log(JSON.stringify(error));
    };


    render() {
        setInitialCookie();
        Moment.startPooledTimer(); //Sets all timers at the same time to save some performance
        let content = !!this.state.isAuthenticated ? (
            <Provider store={store}>
                <div>
                    <Router>
                        <div className="s-google">
                            <ProfilePopup
                                img={
                                    JSON.parse(localStorage.getItem("user"))
                                        ._json.picture
                                }
                                name={
                                    JSON.parse(localStorage.getItem("user"))
                                        .displayName
                                }
                                email={
                                    JSON.parse(localStorage.getItem("user"))
                                        .emails[0].value
                                }
                                logout={this.logout.bind(this)}
                            />
                        </div>

                        <SelectBoard
                            name={
                                JSON.parse(localStorage.getItem("user"))
                                    .displayName
                            }
                        />
                    </Router>
                </div>
            </Provider>
        ) : (
            <div className='o-login__container'>
                <div className="o-login__image"/>
                <div className="o-login">
                    <div className="o-login__label-big">
                        <div>Even kijken hoe we er voor staan?</div>
                        <div className='o-login__label-small'>Eerst even inloggen</div>
                    </div>
                </div>

                <div className="o-login__googlebutton">
                    <GoogleLogin
                        clientId="587794428110-0pofojonff5l59luc234kalskp52mel0.apps.googleusercontent.com"
                        buttonText="Login met een Toppy email"
                        onSuccess={this.googleResponse}
                        onFailure={this.onFailure}
                    />
                </div>
            </div>
        );
        return <div className="App">{content}</div>;
    }
}

export default App;
