import React, {Component} from "react";
import Progress from "./common/Charts/Progress";
import {connect} from "react-redux";
import {openWebsocket} from "../actions/actions";
import PropTypes from "prop-types";
import {getNamespaceSettings} from "../actions/actions";
import TimeToCutoff from "./Warehouse/TimeToCutoff";
import Loader from "./common/Loader";
import InfoBars from "./Warehouse/InfoBars";

import {NAMESPACE_WAREHOUSE, NAMESPACE_RMA} from "../actions/types";
import Header from "./common/Headers/Header";
import DoubleHeader from './common/Headers/DoubleHeader'
import NumberBox from "./common/NumberBoxes/NumberBox";
import {getSettings} from "./Warehouse/ActiveEmployees";

class WarehouseTeam extends Component {
    componentDidMount() {
        this.connectSockets();
        this.props.getNamespaceSettings(NAMESPACE_WAREHOUSE);
    }

    connectSockets = () => {
        this.props.openWebsocket(NAMESPACE_WAREHOUSE);
        this.props.openWebsocket(NAMESPACE_RMA);
    };

    render() {
        if (typeof this.props.totalSummary !== "undefined") {
            const {props} = this;
            return (
                <div className={props.tvMode ? "warehouse tv" : "warehouse"}>
                    <div id="left" className="medium-column">
                        <div className="block">
                            <DoubleHeader
                                label1={"Pending"}
                                number1={props.totalSummary.count_new}
                                label2={"Ready"}
                                number2={props.totalSummary.count_ready}
                                color={"blue"}
                                tooltip={"Regels"}
                                secondNumber1={props.totalSummary.shipment_count_new}
                                tooptipSecondNumber1={"Zendingen"}
                                secondNumber2={props.totalSummary.shipment_count_ready}
                                tooptipSecondNumber2={"Zendingen"}
                            />
                        </div>
                        <div className="block">
                            <div className="c-warehouseLabel">Pakket</div>
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Pending"}
                                    value={props.uglySummary.count_new + props.mechanisatieSummary.count_new + props.zonderDoosSummary.count_new}
                                />
                                <NumberBox
                                    label={"Ready"}
                                    value={props.uglySummary.count_ready + props.mechanisatieSummary.count_ready + props.zonderDoosSummary.count_ready}
                                />
                            </div>
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Picking"}
                                    value={props.uglySummary.count_processing + props.mechanisatieSummary.count_processing + props.zonderDoosSummary.count_processing}
                                />
                                <NumberBox
                                    label={"Packing"}
                                    value={props.uglySummary.count_picked + props.mechanisatieSummary.count_picked + props.zonderDoosSummary.count_picked}
                                />
                                <NumberBox
                                    label={"Klaar"}
                                    value={props.uglySummary.count_done + props.mechanisatieSummary.count_done + props.zonderDoosSummary.count_done}
                                />
                                <NumberBox
                                    label={"Labels"}
                                    value={props.uglySummary.labels_this_day + props.mechanisatieSummary.labels_this_day + props.zonderDoosSummary.labels_this_day}
                                />
                            </div>
                            <div className="c-warehouseLabel">{props.singleSummary.flow}</div>
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Pending"}
                                    value={props.singleSummary.count_new}
                                />
                                <NumberBox
                                    label={"Ready"}
                                    value={props.singleSummary.count_ready}
                                />
                            </div>
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Picking"}
                                    value={props.singleSummary.count_processing}
                                />
                                <NumberBox
                                    label={"Packing"}
                                    value={props.singleSummary.count_picked}
                                />
                                <NumberBox
                                    label={"Klaar"}
                                    value={props.singleSummary.count_done}
                                />
                                <NumberBox
                                    label={"Labels"}
                                    value={props.singleSummary.labels_this_day}
                                />
                            </div>
                            {typeof props.settings !== "undefined" && (
                                <div className="s-number-boxes">
                                    <NumberBox
                                        label={"Pickers"}
                                        value={props.settings.pakketPickers}
                                    />
                                    <NumberBox
                                        label={"Packers"}
                                        value={props.settings.pakketPackers}
                                    />
                                </div>
                            )}
                            <div className="o-progressbar-container">
                                <Progress
                                    progression={
                                        (
                                            ((props.uglySummary.count_done + props.singleSummary.count_done + props.vacuumSummary.count_done + props.zonderDoosSummary.count_done + props.mechanisatieSummary.count_done) /
                                                ((props.uglySummary.count_new + props.singleSummary.count_new + props.vacuumSummary.count_new + props.zonderDoosSummary.count_new + props.mechanisatieSummary.count_new) +
                                                    (props.uglySummary.count_ready + props.singleSummary.count_ready + props.vacuumSummary.count_ready + props.zonderDoosSummary.count_ready + props.mechanisatieSummary.count_ready) +
                                                    (props.uglySummary.count_processing + props.singleSummary.count_processing + props.vacuumSummary.count_processing + props.zonderDoosSummary.count_processing + props.mechanisatieSummary.count_processing) +
                                                    (props.uglySummary.count_picked + props.singleSummary.count_picked + props.vacuumSummary.count_picked + props.zonderDoosSummary.count_picked + props.mechanisatieSummary.count_picked) +
                                                    (props.uglySummary.count_done + props.singleSummary.count_done + props.vacuumSummary.count_done + props.zonderDoosSummary.count_done + props.mechanisatieSummary.count_done))) *
                                            100
                                        ).toFixed(0) / 1
                                    }
                                    goal={100}
                                    label={"Voortgang"}
                                    showText={false}
                                    showIncredible={false}
                                />
                                <Progress
                                    progression={props.uglySummary.picking_per_hour + props.singleSummary.picking_per_hour + props.vacuumSummary.picking_per_hour + props.zonderDoosSummary.picking_per_hour + props.mechanisatieSummary.picking_per_hour}
                                    goal={
                                        props.settings.pakketPickers * props.settings.pakketPickGoal
                                    }
                                    label={"Picken per uur"}
                                    showText={false}
                                    showIncredible={false}
                                />
                                <Progress
                                    progression={props.uglySummary.labels_per_hour + props.singleSummary.labels_per_hour + props.vacuumSummary.labels_per_hour + props.zonderDoosSummary.labels_per_hour + props.mechanisatieSummary.labels_per_hour}
                                    goal={
                                        props.settings.pakketPackers * props.settings.pakketPackGoal
                                    }
                                    label={"Labels per uur"}
                                    showText={false}
                                    showIncredible={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="center" className="medium-column">
                        <div className="block">
                            <DoubleHeader
                                label1={"Picking"}
                                number1={props.totalSummary.count_processing}
                                label2={"Packing"}
                                number2={props.totalSummary.count_picked}
                                color={"yellow"}
                                tooltip={"Regels"}
                                secondNumber1={props.totalSummary.shipment_count_processing}
                                tooptipSecondNumber1={"Zendingen"}
                                secondNumber2={props.totalSummary.shipment_count_picked}
                                tooptipSecondNumber2={"Zendingen"}
                            />
                        </div>
                        <div className="block">
                            <div className="c-warehouseLabel">{props.palletSummary.flow}</div>
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Pending"}
                                    value={props.palletSummary.count_new}
                                />
                                <NumberBox
                                    label={"Ready"}
                                    value={props.palletSummary.count_ready}
                                />
                            </div>
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Picking"}
                                    value={props.palletSummary.count_processing}
                                />
                                <NumberBox
                                    label={"Packing"}
                                    value={props.palletSummary.count_picked}
                                />
                                <NumberBox
                                    label={"Klaar"}
                                    value={props.palletSummary.count_done}
                                />
                                <NumberBox
                                    label={"Labels"}
                                    value={props.palletSummary.labels_this_day}
                                />
                            </div>
                            <div className="c-warehouseLabel">{props.vacuumSummary.flow}</div>
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Pending"}
                                    value={props.vacuumSummary.count_new}
                                />
                                <NumberBox
                                    label={"Ready"}
                                    value={props.vacuumSummary.count_ready}
                                />
                            </div>
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Picking"}
                                    value={props.vacuumSummary.count_processing}
                                />
                                <NumberBox
                                    label={"Packing"}
                                    value={props.vacuumSummary.count_picked}
                                />
                                <NumberBox
                                    label={"Klaar"}
                                    value={props.vacuumSummary.count_done}
                                />
                                <NumberBox
                                    label={"Labels"}
                                    value={props.vacuumSummary.labels_this_day}
                                />
                            </div>
                            {typeof props.settings !== "undefined" && (
                                <div className="s-number-boxes">
                                    <NumberBox
                                        label={"Pickers"}
                                        value={props.settings.palletPickers}
                                    />
                                    <NumberBox
                                        label={"Packers"}
                                        value={props.settings.palletPackers}
                                    />
                                </div>
                            )}
                            <div className="o-progressbar-container">
                                <Progress
                                    progression={
                                        (
                                            (props.palletSummary.count_done /
                                                (props.palletSummary.count_new +
                                                    props.palletSummary.count_ready +
                                                    props.palletSummary.count_processing +
                                                    props.palletSummary.count_picked +
                                                    props.palletSummary.count_done)) *
                                            100
                                        ).toFixed(0) / 1
                                    }
                                    goal={100}
                                    label={"Voortgang"}
                                    showText={false}
                                    showIncredible={false}
                                />
                                <Progress
                                    progression={props.palletSummary.picking_per_hour}
                                    goal={
                                        props.settings.palletPickers * props.settings.palletPickGoal
                                    }
                                    label={"Picken per uur"}
                                    showText={false}
                                    showIncredible={false}
                                />
                                <Progress
                                    progression={props.palletSummary.labels_per_hour}
                                    goal={
                                        props.settings.palletPickers * props.settings.palletPackGoal
                                    }
                                    label={"Labels per uur"}
                                    showText={false}
                                    showIncredible={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="right" className="medium-column">
                        <div className="block">
                            <Header
                                number={props.totalSummary.count_done}
                                color={"green"}
                                label={"Klaar"}
                                secondNumber={props.totalSummary.shipment_count_done}
                                tooltip={"Regels"}
                                tooptipSecondNumber={"Zendingen"}
                            />
                        </div>
                        <div className="block">
                            <div className="s-number-boxes">
                                <NumberBox
                                    label={"Order per uur"}
                                    value={props.ordersPerHour || 0}
                                />
                            </div>
                        </div>
                        <div className="block">
                            <InfoBars/>
                        </div>
                        {" "}
                        <div className="block">
                            <TimeToCutoff
                                pakketCutOff={props.settings.pakketCutOff}
                                palletCutOff={props.settings.palletCutOff}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Loader show={true}/>;
        }
    }
}

WarehouseTeam.propTypes = {
    openWebsocket: PropTypes.func.isRequired,
    getNamespaceSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    let result = {
        settings: {
            pickers: 1,
            packers: 1,
        },
    };

    if (typeof state.data["event-stocky-state-counts"] !== "undefined") {
        result.uglySummary = state.data[
            "event-stocky-state-counts"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.flow === "Ugly");
        result.mechanisatieSummary = state.data[
            "event-stocky-state-counts"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && ["Mechanisatie", "Catch all"].includes(item.flow));
        result.zonderDoosSummary = state.data[
            "event-stocky-state-counts"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.flow === "Zonder doos");
        result.singleSummary = state.data[
            "event-stocky-state-counts"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.flow === "Single");
        result.vacuumSummary = state.data[
            "event-stocky-state-counts"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.flow === "Vacuumheffer");
        result.palletSummary = state.data[
            "event-stocky-state-counts"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && ["Wholesale", "Pallet"].includes(item.flow));

        if (typeof result.palletSummary == 'undefined') {
            result.palletSummary = {
                "flow": "Pallet",
                "count_new": 0,
                "count_ready": 0,
                "count_processing": 0,
                "count_picked": 0,
                "count_done": 0,
                "shipment_count_new": 0,
                "shipment_count_ready": 0,
                "shipment_count_processing": 0,
                "shipment_count_picked": 0,
                "shipment_count_done": 0
            }
        }

        if (typeof result.mechanisatieSummary == 'undefined') {
            result.mechanisatieSummary = {
                "flow": "Mechanisatie",
                "count_new": 0,
                "count_ready": 0,
                "count_processing": 0,
                "count_picked": 0,
                "count_done": 0,
                "shipment_count_new": 0,
                "shipment_count_ready": 0,
                "shipment_count_processing": 0,
                "shipment_count_picked": 0,
                "shipment_count_done": 0
            }
        }

        if (typeof result.uglySummary == 'undefined') {
            result.uglySummary = {
                "flow": "Ugly",
                "count_new": 0,
                "count_ready": 0,
                "count_processing": 0,
                "count_picked": 0,
                "count_done": 0,
                "shipment_count_new": 0,
                "shipment_count_ready": 0,
                "shipment_count_processing": 0,
                "shipment_count_picked": 0,
                "shipment_count_done": 0
            }
        }

        if (typeof result.zonderDoosSummary == 'undefined') {
            result.zonderDoosSummary = {
                "flow": "Zonder doos",
                "count_new": 0,
                "count_ready": 0,
                "count_processing": 0,
                "count_picked": 0,
                "count_done": 0,
                "shipment_count_new": 0,
                "shipment_count_ready": 0,
                "shipment_count_processing": 0,
                "shipment_count_picked": 0,
                "shipment_count_done": 0
            }
        }

        if (typeof result.singleSummary == 'undefined') {
            result.singleSummary = {
                "flow": "Single",
                "count_new": 0,
                "count_ready": 0,
                "count_processing": 0,
                "count_picked": 0,
                "count_done": 0,
                "shipment_count_new": 0,
                "shipment_count_ready": 0,
                "shipment_count_processing": 0,
                "shipment_count_picked": 0,
                "shipment_count_done": 0
            }
        }

        if (typeof result.vacuumSummary == 'undefined') {
            result.vacuumSummary = {
                "flow": "Vacuumheffer",
                "count_new": 0,
                "count_ready": 0,
                "count_processing": 0,
                "count_picked": 0,
                "count_done": 0,
                "shipment_count_new": 0,
                "shipment_count_ready": 0,
                "shipment_count_processing": 0,
                "shipment_count_picked": 0,
                "shipment_count_done": 0
            }
        }
        //
        // result.pakketSummary.count_new = parseInt(result.pakketSummary.count_new + result.singleSummary.count_new + result.vacuumSummary.count_new);
        // result.pakketSummary.count_ready = parseInt(result.pakketSummary.count_ready + result.singleSummary.count_ready + result.vacuumSummary.count_ready);
        // result.pakketSummary.count_processing = parseInt(result.pakketSummary.count_processing + result.singleSummary.count_processing + result.vacuumSummary.count_processing);
        // result.pakketSummary.count_picked = parseInt(result.pakketSummary.count_picked + result.singleSummary.count_picked + result.vacuumSummary.count_picked);
        // result.pakketSummary.count_done = parseInt(result.pakketSummary.count_done + result.singleSummary.count_done + result.vacuumSummary.count_done);
        // result.pakketSummary.shipment_count_new = parseInt(result.pakketSummary.shipment_count_new + result.singleSummary.shipment_count_new + result.vacuumSummary.shipment_count_new);
        // result.pakketSummary.shipment_count_ready = parseInt(result.pakketSummary.shipment_count_ready + result.singleSummary.shipment_count_ready + result.vacuumSummary.shipment_count_ready);
        // result.pakketSummary.shipment_count_processing = parseInt(result.pakketSummary.shipment_count_processing + result.singleSummary.shipment_count_processing + result.vacuumSummary.shipment_count_processing);
        // result.pakketSummary.shipment_count_picked = parseInt(result.pakketSummary.shipment_count_picked + result.singleSummary.shipment_count_picked + result.vacuumSummary.shipment_count_picked);
        // result.pakketSummary.shipment_count_done = parseInt(result.pakketSummary.shipment_count_done + result.singleSummary.shipment_count_done + result.vacuumSummary.shipment_count_done);

        result.totalSummary = {
            count_new:
                result.uglySummary.count_new +
                result.mechanisatieSummary.count_new +
                result.zonderDoosSummary.count_new +
                result.palletSummary.count_new +
                result.singleSummary.count_new +
                result.vacuumSummary.count_new,
            count_ready:
                result.uglySummary.count_ready +
                result.mechanisatieSummary.count_ready +
                result.zonderDoosSummary.count_ready +
                result.palletSummary.count_ready +
                result.singleSummary.count_ready +
                result.vacuumSummary.count_ready,
            count_processing:
                result.uglySummary.count_processing +
                result.mechanisatieSummary.count_processing +
                result.zonderDoosSummary.count_processing +
                result.palletSummary.count_processing +
                result.singleSummary.count_processing +
                result.vacuumSummary.count_processing,
            count_picked:
                result.uglySummary.count_picked +
                result.mechanisatieSummary.count_picked +
                result.zonderDoosSummary.count_picked +
                result.palletSummary.count_picked +
                result.singleSummary.count_picked +
                result.vacuumSummary.count_picked,
            count_done:
                result.uglySummary.count_done +
                result.mechanisatieSummary.count_done +
                result.zonderDoosSummary.count_done +
                result.palletSummary.count_done +
                result.singleSummary.count_done +
                result.vacuumSummary.count_done,
            shipment_count_new:
                result.uglySummary.shipment_count_new +
                result.mechanisatieSummary.shipment_count_new +
                result.zonderDoosSummary.shipment_count_new +
                result.palletSummary.shipment_count_new +
                result.singleSummary.shipment_count_new +
                result.vacuumSummary.shipment_count_new,
            shipment_count_ready:
                result.uglySummary.shipment_count_ready +
                result.mechanisatieSummary.shipment_count_ready +
                result.zonderDoosSummary.shipment_count_ready +
                result.palletSummary.shipment_count_ready +
                result.singleSummary.shipment_count_ready +
                result.vacuumSummary.shipment_count_ready,
            shipment_count_processing:
                result.uglySummary.shipment_count_processing +
                result.mechanisatieSummary.shipment_count_processing +
                result.zonderDoosSummary.shipment_count_processing +
                result.palletSummary.shipment_count_processing +
                result.singleSummary.shipment_count_processing +
                result.vacuumSummary.shipment_count_processing,
            shipment_count_picked:
                result.uglySummary.shipment_count_picked +
                result.mechanisatieSummary.shipment_count_picked +
                result.zonderDoosSummary.shipment_count_picked +
                result.palletSummary.shipment_count_picked +
                result.singleSummary.shipment_count_picked +
                result.vacuumSummary.shipment_count_picked,
            shipment_count_done:
                result.uglySummary.shipment_count_done +
                result.mechanisatieSummary.shipment_count_done +
                result.zonderDoosSummary.shipment_count_done +
                result.palletSummary.shipment_count_done +
                result.singleSummary.shipment_count_done +
                result.vacuumSummary.shipment_count_done,
        };
    }

    if (
        typeof state.data["event-stocky-current-picking-per-hour"] !== "undefined"
        && typeof result.uglySummary !== "undefined"
        && typeof result.mechanisatieSummary !== "undefined"
        && typeof result.zonderDoosSummary !== "undefined"
        && typeof result.palletSummary !== "undefined"
        && typeof result.vacuumSummary !== "undefined"
        && typeof result.singleSummary !== "undefined"
    ) {
        result.uglySummary.picking_per_hour = state.data[
            "event-stocky-current-picking-per-hour"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Ugly").picking_per_hour;

        // result.zonderDoosSummary.picking_per_hour = state.data[
        //     "event-stocky-current-picking-per-hour"
        //     ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Zonder doos").picking_per_hour;
        result.zonderDoosSummary.picking_per_hour = 0;

        result.mechanisatieSummary.picking_per_hour = state.data[
            "event-stocky-current-picking-per-hour"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && ["Mechanisatie", "Catch all"].includes(item.name)).picking_per_hour;

        result.palletSummary.picking_per_hour = state.data[
            "event-stocky-current-picking-per-hour"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && ["Wholesale", "Pallet"].includes(item.name)).picking_per_hour;

        // result.vacuumSummary.picking_per_hour = state.data[
        //     "event-stocky-current-picking-per-hour"
        //     ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Vacuumheffer").picking_per_hour;
        result.vacuumSummary.picking_per_hour = 0

        result.singleSummary.picking_per_hour = state.data[
            "event-stocky-current-picking-per-hour"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Single").picking_per_hour;

        // result.pakketSummary.picking_per_hour = parseInt(result.pakketSummary.picking_per_hour + result.singleSummary.picking_per_hour + result.vacuumSummary.picking_per_hour);
    }

    if (
        typeof state.data["event-stocky-current-labels-per-hour"] !== "undefined"
        && typeof result.uglySummary !== "undefined"
        && typeof result.mechanisatieSummary !== "undefined"
        && typeof result.zonderDoosSummary !== "undefined"
        && typeof result.palletSummary !== "undefined"
        && typeof result.vacuumSummary !== "undefined"
        && typeof result.singleSummary !== "undefined"
    ) {
        result.uglySummary.labels_per_hour = state.data[
            "event-stocky-current-labels-per-hour"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Ugly").labels_per_hour;

        // result.zonderDoosSummary.labels_per_hour = state.data[
        //     "event-stocky-current-labels-per-hour"
        //     ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Zonder doos").labels_per_hour;
        result.zonderDoosSummary.labels_per_hour = 0

        result.mechanisatieSummary.labels_per_hour = state.data[
            "event-stocky-current-labels-per-hour"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && ["Mechanisatie", "Catch all"].includes(item.name)).labels_per_hour;

        result.palletSummary.labels_per_hour = state.data[
            "event-stocky-current-labels-per-hour"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && ["Wholesale", "Pallet"].includes(item.name)).labels_per_hour;

        // result.vacuumSummary.labels_per_hour = state.data[
        //     "event-stocky-current-labels-per-hour"
        //     ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Vacuumheffer").labels_per_hour;
        result.vacuumSummary.labels_per_hour = 0

        result.singleSummary.labels_per_hour = state.data[
            "event-stocky-current-labels-per-hour"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Single").labels_per_hour;

        // result.pakketSummary.labels_per_hour = parseInt(result.pakketSummary.labels_per_hour + result.singleSummary.labels_per_hour + result.vacuumSummary.labels_per_hour);
    }

    if (
        typeof state.data["event-stocky-current-labels-this-day"] !== "undefined"
        && typeof result.uglySummary !== "undefined"
        && typeof result.mechanisatieSummary !== "undefined"
        && typeof result.zonderDoosSummary !== "undefined"
        && typeof result.palletSummary !== "undefined"
        && typeof result.vacuumSummary !== "undefined"
        && typeof result.singleSummary !== "undefined"
    ) {
        result.uglySummary.labels_this_day = state.data[
            "event-stocky-current-labels-this-day"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Ugly").labels_this_day;

        result.mechanisatieSummary.labels_this_day = state.data[
            "event-stocky-current-labels-this-day"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && ["Mechanisatie", "Catch all"].includes(item.name)).labels_this_day;

        // result.zonderDoosSummary.labels_this_day = state.data[
        //     "event-stocky-current-labels-this-day"
        //     ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Zonder doos").labels_this_day;
        result.zonderDoosSummary.labels_this_day = 0

        result.palletSummary.labels_this_day = state.data[
            "event-stocky-current-labels-this-day"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && ["Wholesale", "Pallet"].includes(item.name)).labels_this_day;

        // result.vacuumSummary.labels_this_day = state.data[
        //     "event-stocky-current-labels-this-day"
        //     ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Vacuumheffer").labels_this_day;
        result.vacuumSummary.labels_this_day = 0;

        result.singleSummary.labels_this_day = state.data[
            "event-stocky-current-labels-this-day"
            ].find((item) => ["HEUTS"].includes(item.warehouse) && item.name === "Single").labels_this_day;

        // result.pakketSummary.labels_this_day = parseInt(result.pakketSummary.labels_this_day + result.singleSummary.labels_this_day + result.vacuumSummary.labels_this_day);
    }

    result.settings = getSettings(state);
    result.tvMode = state.app.tvMode;

    return result;
};

export default connect(mapStateToProps, {
    openWebsocket,
    getNamespaceSettings,
})(WarehouseTeam);
