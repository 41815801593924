import io from "socket.io-client";
import {
  OPEN_WEBSOCKET,
  RECEIVE_DATA,
  UPDATE_SOCKET_OBJECT,
  NAMESPACE_DEFAULT,
  NAMESPACE_CUSTOMER_SERVICE,
  NAMESPACE_WAREHOUSE,
  NAMESPACE_RMA,
  NAMESPACE_CONTENT,
} from "../actions/types";

var sockets = {};
var ioQuery = new URLSearchParams();
ioQuery.set("hostname", window.location.hostname);

const events = {
  [NAMESPACE_DEFAULT]: [
    "realtime-analytics",
    "event-order-place",
  ],
  [NAMESPACE_CUSTOMER_SERVICE]: [
    "event-current-stats",
    "event-kiyoh-latest-reviews",
    "event-revenue-last-24-hours",
    "event-robin-tickets",
  ],
  [NAMESPACE_WAREHOUSE]: [
    "event-current-stats",
    "event-stocky-current-picking-per-hour",
    "event-stocky-current-labels-per-hour",
    "event-stocky-current-labels-this-day",
    "event-stocky-state-counts",
    "event-stocky-inbound-stats",
    "event-stocky-inbound-order-stats",
  ],
  [NAMESPACE_RMA]: ["event-rma-per-state-count"],
  [NAMESPACE_CONTENT]: [
    "event-current-stats",
    "event-kiyoh-latest-reviews",
    "event-revenue-last-24-hours",
    "event-revenue-per-department",
    "event-latest-new-product-sales",
    "analytics-users"
  ],
};

const handleSocketMessage = (eventName, result, store) => {
  store.dispatch({
    type: RECEIVE_DATA,
    payload: {
      event: eventName,
      data: result,
    },
  });
};

const socketMiddleware = (store) => (next) => (action) => {
  // var backendUrl = window.location.protocol + '//socket.' + window.location.hostname + ':3001';
  var backendUrl = window.location.protocol + "//socket." + window.location.hostname;

  if (
    action.type === OPEN_WEBSOCKET &&
    typeof sockets[action.namespace] === "undefined"
  ) {
    var socketUrl = backendUrl;

    if (action.namespace !== "default") {
      socketUrl = socketUrl + "/" + action.namespace;
    }

    sockets[action.namespace] = io(socketUrl, {
      reconnection: true,
      reconnectionDelayMax: 60000,
      reconnectionAttempts: 100,
      query: ioQuery.toString(),
    });
    sockets[action.namespace].on("connect", () => {
      console.log(
        "connected to " + action.namespace + " socket",
        sockets[action.namespace]
      );
    });
    events[action.namespace].forEach((eventName) => {
      sockets[action.namespace].on(eventName, (data) =>
        handleSocketMessage(eventName, data, store)
      );
    });

    store.dispatch({
      type: UPDATE_SOCKET_OBJECT,
      payload: {
        socket: sockets[action.namespace],
        namespace: action.namespace,
      },
    });
  }

  next(action);
};

export default socketMiddleware;
